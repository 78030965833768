<template>
  <div v-if="type=='array' || type=='object'">
    <v-icon @click="$emit('click','addGroup')" title="Delete" :size="size">mdi-plus</v-icon>
  </div>
</template>
<script>
export default {
  props: {
    size: Number,
    result: Object,
  },
  computed: {
    type() {
      let type = this.result.valueLine.type;

      return type;
    },
  },
};
</script>